body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-container {
  padding: 20px;
  padding-top: 110px;
}
.main-container.fullscreen {
    padding: 0px;
      padding-top: 90px;
}
.innner-header {
  padding: 0px 20px;
}
.comming-soom-title {
      color: #ccc;
      text-align: center;
      margin-top: 100px;
}
.c-pointer {
  cursor: pointer;
}
.modeBtn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid #dedede;
  z-index: 10;
  height: 100px;
    width: 100px;
    overflow: hidden;
    cursor: pointer;
}
.modeBtn .map-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modeBtn:hover {
  border: 4px solid #dedede;
}
.toster-div {
  position: fixed;
  background-color: #fff;
  z-index: 9999;
  top: 0;
  right: 0;
}
.errorMsg {
  margin: 0;
  font-size: 14px;
  color: #db2128;
}
small.password-note {
  font-size: 12px;
}
.otp-input-wrraper > div {
  justify-content: center;
  gap: 10px;
}
.otp-input input {
      border: 0;
      background: #f0f0f0;
      height: 46px;
      width: 40px !important;
      border-bottom: 1px solid #9e9e9e;
}
.otp-input input:focus {
  outline: none;
}
.theme-btn {
  background-color: #0abe29;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #0abe29;
  padding: 5px;
  min-width: 100px;
  flex: 1;
  font-weight: 600;
  width: fit-content;
  flex: 0;
  cursor: pointer;
}

.theme-btn.bordered {
  background-color: transparent;
  color: #001323;
}

.map-searchbar {
  position: absolute;
    top: 120px;
    z-index: 2;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
}
.map-searchbar.without-login {
  top: 30px;
}
.main-filter-title {
  font-size: 18px;
  font-weight: 700;
  background-color: #e1e1e1;
  padding: 5px;
  margin-bottom: 20px;
}
.filter-btn-group {
  display: flex;
  gap: 10px;
}
.filter-btn-group button {
  flex: 1;
}
.autocomplete-dropdown-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
    box-shadow: 0px 0px 4px #0000004d;
    border-radius: 8px;
    overflow: hidden;
    padding: 45px 0px 0px 0px;
    background-color: #fff;
}
.serch-loading {
    background-color: #fafafa;
    padding: 5px 10px;
}
.no-data-container {
margin: 30px auto;
display: table;
}
.no-data-container img.no-data-img {
  height: 160px;
    width: 160px;
    object-fit: contain;
    margin-bottom: 20px;
    opacity: 0.3;
}
.no-data-container h4.no-data-text {
  font-weight: 700;
  opacity: 0.3;
}
 @keyframes rotate {
   from {
     transform: rotate(0deg);
   }

   to {
     transform: rotate(360deg);
   }
 }


 @-webkit-keyframes rotate {
   from {
     -webkit-transform: rotate(0deg);
   }

   to {
     -webkit-transform: rotate(360deg);
   }
 }

 .dataLoader {
   width: 60px;
   height: 60px;
   margin: 20px auto;
   border: solid 8px #0abe29;
   border-radius: 50%;
   border-right-color: transparent;
   border-bottom-color: transparent;
   -webkit-transition: all 0.5s ease-in;
   -webkit-animation-name: rotate;
   -webkit-animation-duration: 1.0s;
   -webkit-animation-iteration-count: infinite;
   -webkit-animation-timing-function: linear;

   transition: all 0.5s ease-in;
   animation-name: rotate;
   animation-duration: 1.0s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
 }

 
@media (max-width:991.98px) {
  .searchbar {
    margin-bottom: 20px !important;
  }
  .main-filter-title-btn {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #c8c8c8;
    background: #f3f3f3;
    margin-bottom: 20px;
    position: relative;
    font-weight: 600;
    padding: 8px 5px;
  }
    .main-filter-title-btn:after {
      content: '';
      height: 10px;
      width: 10px;
      position: absolute;
      right: 10px;
      top: 40%;
      transform: translateY(-50%) rotate(45deg);
      border-bottom: 2px solid #000;
      border-left: 2px solid transparent;
        border-top: 2px solid transparent;
      border-right: 2px solid #000;

    }
}