.header-wrapper {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 90px;
    border-radius: 0;
    background-color: #fff;
    box-shadow: 0px 0px 4px #0000002e;
}

.navbar-nav {
    width: 100%;
}

.navbar-nav li.nav-item {
    flex: 1;
    text-align: center;
    border-left: 1px solid #e6e6f2;
}

.navbar-nav li.nav-item:last-child {
    border-right: 1px solid #e6e6f2;
}

.navbar-collapse.collapse.toggler-data-wrapper {
    height: 100% !important;
    padding: 0;
}

.navbar-list-wrapper {
    height: 100%;
}

.navbar-list-wrapper li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-list-wrapper li a {
    color: #001323;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
}

.navbar-list-wrapper li a:hover {
    text-decoration: none;
}

.profile-img-wrapper {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    overflow: hidden;
}

.profile-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.proile-dropdown {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-name-wrapper {
    flex: 1;
}

.user-name-wrapper p {
    color: #001323;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    min-width: 140px;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-name-wrapper span {
    color: #001323;
    font-size: 16px;
}

.dropdown-menu.proile-dropdown-menu {
    width: 350px;
    right: -14px;
    left: unset;
    padding: 0;
    top: calc(100% + 32px);
    background-color: #f7f7f7;
}

.dropdown-menu.proile-dropdown-menu::before {
    content: "";
    height: 20px;
    width: 20px;
    background: #f7f7f7;
    position: absolute;
    top: -11px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #d2d2d2;
    border-left: 1px solid #d2d2d2;
}

.proile-dropdown-menu .item-list .item .inner-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.proile-dropdown-menu .item-list .item .inner-item:last-child {
    margin-bottom: 0;
}

.proile-dropdown-menu .item-list .item a:hover {
    text-decoration: none;
}

.proile-dropdown-menu .item-list .item:hover {
    background-color: #ededed;
}

.proile-dropdown-menu .item-list .item .inner-item i {
    color: #001323;
    font-size: 20px;
}

.proile-dropdown-menu .item-list .item .inner-item p {
    color: #001323;
    font-size: 16px;
}

.dropdown-menu .inner-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.dropdown-menu .inner-item p {
    margin: 0;
}

.dropdown-toggle::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.mobile-header-logo {
    display: none;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
}

.close {
    display: none;
}

.credit-count {
    border-right: 1px solid #e6e6f2;
    height: 100%;
}

.navbar-nav .nav-item {
    position: relative;
}
.navbar-nav .nav-item a {
    height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
}

.navbar-nav .nav-item a:after {
    content: '';
    height: 4px;
    width: 0%;
    background: #0abe29;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.4s;
}
.navbar-nav .nav-item a.active {
    background-color: #f9fffa;
}
.navbar-nav .nav-item a.active:after , .navbar-nav .nav-item a:hover:after {
    width: 100%;
}
.navbar-nav .nav-item a.active div {
    color: #0abe29;
}
        .navbar-collapse {
           height: 90px !important;
        }

@media (max-width: 1199.98px) {
    .header-main-logo {
        flex: 1;
    }
.navbar-nav .nav-item a {
    justify-content: start;
}
    .dropdown-menu-header {
        border-right: 1px solid #e6e6f2;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 20px;
    }

    .collapse,
    .collapsing {
        display: block !important;
        position: fixed;
        left: 0px;
        top: 0px;
        transform: translate(-100%);
        transition: 0.3s;
        background: white;
        width: 100%;
        height: 100vh !important;
        max-width: 320px;
    }

    .collapse.show {
        transform: translate(0%);
    }

    .navbar-list-wrapper {
        height: auto;
    }

    .navbar-nav {
        margin-top: 10px;
    }

    .navbar-nav li.nav-item {
        text-align: left;
        border-left: 0px;
        justify-content: start;
        padding: 15px 20px;
    }

    .navbar-collapse.collapse.toggler-data-wrapper {
        padding: 0;
        z-index: 101 !important;
    }

    .mobile-header-logo {
        display: block;
        padding: 23px 20px;
        border-bottom: 1px solid #e6e6f2;
    }

    .mobile-menu-overlay {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: #0000007a;
        z-index: 99;
        max-width: 100vw;
        transition: 0s;
    }

    .close {
        position: absolute;
        right: -16px;
        top: 30px;
        left: unset !important;
        width: 34px;
        height: 34px !important;
        opacity: 1;
        background: #fff;
        border: 1px solid #e6e6f2;
        border-radius: 100%;
        cursor: pointer;
        box-shadow: 0px 0px 4px #000000;
    }

    .close:before,
    .close:after {
        position: absolute;
        left: 50%;
        top: 50%;
        content: " ";
        height: 22px;
        width: 2px;
        background-color: #333;
    }

    .close:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    .close:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@media (max-width: 575.99px) {
    .user-name-wrapper {
        display: none;
    }

    .dropdown-toggle::after {
        content: none;
    }
}