.form-group {
    margin-bottom: 16px !important;
}

.form-control {
    margin-bottom: 16px !important;
    border-radius: 0;
    padding: 16px 10px;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: 1px solid #ced4da;
    background-color: #f7f7f7;
}

.send-btn {
    background-color: #0abe29 !important;
    color: #fff;
    margin-top: 20px;
    width: 100%;
    border-radius: 0;
    padding: 16px 10px !important;
    font-weight: 600;
}

.send-btn:hover {
    background-color: #249e42;
    color: #fff;
}

.send-btn:active {
    background-color: #0abe29 !important;
    color: #fff;
    border: 0;
}

.single-contact-detail {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 26px;
    color: #000;
    cursor: pointer;
}

.single-contact-detail:hover {
    color: #000;
}

.single-contact-detail .contact-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0abe29;
    border-radius: 100%;
    padding: 2px;
}

.single-contact-detail .contact-icon i {
    color: #fff;
    font-size: 20px;
}

.single-contact-detail .contact-detail .title {
    margin: 0;
    font-size: 20px;
}

.single-contact-detail .contact-detail .detail {
    font-size: 18px;
    margin: 0;
}

/* .form-group > div >input {
    padding: 0;
} */
.successMsg {
    color: #0abe29;
    font-size: 18px;
    font-weight: 600;
}

.contactErrorr {
    margin-top: -10px;
}