.App {
    text-align: center;
    height: 100%;
    width: 100%;
}

.app-label {
    width: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: white;
    pointer-events: none;
}

.map-container {
    height: calc(100vh - 90px);
    width: 100%;
}
.map-container.without-login {
    height: 100vh;
}

.map-container .custom-th .plot-details {
    text-align: center;
}

.map-container .table-data {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #000;
    border-bottom: 0;
}
.table-title-popop {
        font-size: 22px;
            font-weight: 700;
}
.view-detail-tr td:last-child {
    display: none;
}
.clicked-coord-label {
    position: absolute;
    right: 0;
    bottom: 0;
    background: white;
    border-radius: 5px;
}

.clicked-coord-label p {
    margin: 10px;
}

.flex-0 {
    flex: 0 !important;
}

.whats-app-btn {
  min-width: fit-content !important;
}

.table-data {
    display: flex;
}