.ol-attribution.ol-unselectable.ol-control.ol-uncollapsible {
    display: none;
}

#marker {
    width: 20px;
    height: 20px;
    border: 1px solid #088;
    border-radius: 10px;
    background-color: #0FF;
    opacity: 0.5;
}

#vienna {
    text-decoration: none;
    color: white;
    font-size: 11pt;
    font-weight: bold;
    text-shadow: black 0.1em 0.1em 0.2em;
}

.popover-body {
    min-width: 276px;
    box-shadow: 0 15px 30px rgb(0 0 0 / 50%)
}

.popover {
    max-width: 430px;
}

.custom-th {
    text-align: center;
    padding: 5px 10px;
}

.custom-div {
    padding: 2px;
    width: 400px;
}

td.custom-td {
    padding: 2px 10px;
}
tr td.custom-td:nth-child(2) {
    font-weight: 600;
}
.graph-image {
    width: 100%;
}

.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
}

.ol-popup .custom-div .table-data {
    display: flex;
    justify-content: space-between;
}

.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}


/* .ol-popup-closer:after {
  content: "✖";
} */


/* .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 718px;
  width: 475px;
  height: 825px;
  top: 47px;
} */