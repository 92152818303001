.favorite-btn {
    height: 30px;
    width: 30px;
    padding: 2px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #fff;
}

.favorite-btn.unfollow {
    background-color: #a7a7a7;
}

.favorite-btn.follow {
    background-color: #f49928;
}

.toaster-custom {
    position: fixed;
    top: 10px;
    right: 10px;
}

.toaster-custom.success {
    background: #0abe29;
    color: #fff;
}

.toaster-custom.success strong {
    color: #0abe29;
}
.share-btn{
 color: #ffff;
 text-align: center;
 margin-left: auto;
 margin-right: auto;
 display: block;
}

/* .share-btn {
    width: 100%;
    color: #0abe29;
    display: block;
    text-align: center;
} */
.share-btn:hover {
    color: #ffff !important;
}

.share-btn:focus-visible {
    outline: none;
}

.single-doc-item {
    border-bottom: 1px solid #dee2e6;
    padding: 14px;
}

.single-doc-item:last-child {
    border: 0;
}

.searchbar {
    margin-bottom: 30px;
}

.searchbar input {
    width: 100%;
    box-shadow: 0px 0px 4px #0000004d;
    border-radius: 8px;
    padding: 10px;
    border: 0;
    position: relative;
    z-index: 2;
}

.searchbar input:focus {
    outline: none;
}

.searchbar .search-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: rgb(236, 234, 234);
    border: 0;
    border-radius: 0px 8px 8px 0px;
    font-weight: 600;
    padding: 0px 16px;
    border-left: 1px solid #ededed;
}

.single-filter-item .filter-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.toaster-content {
    position: fixed;
    z-index: 999;
    top: 25px;
    right: 25px;
    background: #239b37;
    color: #fff;
}

.fileuploaded-Image {
    margin: 0 auto;
    display: flex;
    width: 50%;
}

.fileUpload-text {
    text-align: center;
    font-size: 15px;
}

.fileUpload-text h6 {
    margin-top: 10px;
}

.fileUpload-text h4 {
    color: #0abe29;
}

.confirmation-modal {
    position: fixed;
    z-index: 101;
    top: 50%;
    left: 50%;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 0px 4px #0000004d;
    min-width: 500px;
    max-height: calc(100vh - 10vh);
    overflow-y: auto;
    max-width: 500px;
    padding: 10px;
}

.overlay-wraper {
    z-index: 100;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #00000054;
    top: 0;
    left: 0;
}

.confirmation-modal .delet-icon {
    background-color: #efefef;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    font-size: 60px;
    margin: 0 auto;
    color: #db2128;
}

.confirmation-modal .card-details {
    /* background-color: #efefef; */
    width: fit-content;
    gap: 30px;
    font-weight: 700;
}

.area-filter .radio-item {
    cursor: pointer;
    margin-bottom: 16px;
}

.area-filter .radio-item:last-child {
    margin-bottom: 0;
}

.area-filter .radio-item input[type="radio"]~.valueInput {
    display: none;
    gap: 10px;
    margin: 5px 0 5px 35px;
}

.area-filter .radio-item input[type="radio"]~.valueInput input {
    max-width: 85px;
    min-width: 85px;
}

.area-filter .radio-item input[type="radio"]~.valueInput input:focus {
    outline: none;
}

.area-filter .radio-item input[type="radio"]:checked~.valueInput {
    display: flex;
}

.area-filter .radio-item input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 14px;
}

.area-filter .radio-item label {
    position: relative;
    top: -3px;
}
.cliam-reqired {
        flex: 1;
            text-align: right;
            padding-right: 12px;
}
@media (max-width: 575.98px) {
    .confirmation-modal {
        min-width: 96%;
        max-width: 96%;
    }
}