table.modal-table {
    width: 440px;
    max-width: 100%;
}

table.modal-table,
table.modal-table tr,
table.modal-table td {
    border: 1px solid rgb(46, 46, 46);
}

table.modal-table .modal-title {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    color: #000;
    padding: 4px;
    position: relative;
}

table.modal-table tr td {
    width: 60%;
    font-size: 16px;
    padding: 5px 8px;
    font-weight: 600;
}

table.modal-table tr td:first-child {
    white-space: pre;
    font-weight: 400;
    width: 40%;
    background-color: aliceblue;
}

table.modal-table tr:first-child td,
table.modal-table tr:last-child td {
    background-color: #eaeaf0;
}

table.modal-table .viewMore-link {
    color: blue;
    font-weight: 700;
    cursor: pointer;
}

table.modal-table .modal-title .favorite-btn-map {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.favorite-btn-map .list-favorite-icon {
    position: initial;
    top: unset;
    left: unset;
    right: unset;
    cursor: pointer;
}

@media (max-width: 575.98px) {
    table.modal-table {
        width: 100%;
    }

    table.modal-table tr {
        display: grid;
    }

    table.modal-table tr td:first-child {
        width: 100% !important;
    }

    table.modal-table tr td {
        width: 100%;
        border: 0px !important;
    }

    table.modal-table .modal-title {
        text-align: left;
    }
        table.modal-table,
        table.modal-table tr,
        table.modal-table td {
            border: 1px solid rgb(181 181 181);
        }
}