
    #nav-title{
        width : 100%;
        height : 90px;
        background-color: #C99577;

    }

    /* .navbar_brand {
        /* float: left; */
        /* height: 50px;
        padding: 15px 15px;
        font-size: 18px;
        line-height: 20px; */
    /* }  */