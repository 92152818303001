.icon-title img {
    height: 35px;
}

.icon-title {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    line-height: 1;
    width: fit-content;
}

.property-detail-list {
    border: 1px solid #E7E7F4;
    border-radius: 12PX;
}

.property-detail-list .property-list-item {
    border-bottom: 1px solid #E7E7F4;
    padding: 8px 16px;
}

.property-detail-list .property-list-item:last-child {
    border-bottom: 0px solid #E7E7F4;
}

.property-detail-list .property-list-item .list-title {
    color: #475467;
    font-size: 14px;
    margin: 0px;
}

.property-detail-list .property-list-item .list-detail {
    color: #011627;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
}

.short-detail-card {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 8px #00000024;
    overflow: hidden;
    position: relative;
}
.short-detail-card .location-img {
    height: 500px;
        width: 100%;
        object-fit: contain;
        background: #ededed;
}
.short-detail-card .detail-wrap {
    padding: 12px;
}

.short-detail-card .detail-wrap p {
    margin: 0;
    color: #001323;
    font-size: 16px;
}
.short-detail-card .detail-wrap p b {
    font-weight: 600;
}
.short-detail-card .detail-wrap p.status {
    margin: 10px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
}
.short-detail-card .detail-wrap .btn-wrap {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 550px;
}

.short-detail-card .detail-wrap .btn-wrap .theme-btn {
    background-color: #0abe29;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #0abe29;
    padding: 5px;
    min-width: 100px;
    flex: 1;
    font-weight: 600;
}

.short-detail-card .detail-wrap .btn-wrap .theme-btn.bordered {
    background-color: transparent;
    color: #001323;
}
.short-detail-card .detail-wrap p.status .available {
    color: #0abe29;
    font-size: 40px;
}
.short-detail-card .detail-wrap p.status .notAvailable {
    color: #da020b;
    font-size: 40px;
}
@media (max-width: 991.98px ) {
    .short-detail-card {
        margin-bottom: 20px;
    }
.property-detail-wrap {
    flex-direction: column-reverse;
}

}