.App {
    text-align: center;
  
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .title{
    margin-top: 30px;
    margin-top: 50px;
    font-size: 30px
  }
  /* .card{
    top: 74px;
  left: 465px;
  width: 436px;
  height: 620px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 40px #00000080;
  border: 1px solid #24D1F1;
  border-radius: 6px;
  opacity: 1;
  margin-left: auto;
      margin-right: auto;
      display: block;
      display: block;
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  } */
  .form-footer{
    width: 100%;
  }
  
  .textfield{
  width: 396px;
  height: 56px;
  background: #F4F7FE 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  }

  .keep-login-wrap {
    display: flex;
    gap: 10px;
  }
  .form-footer {
    display: flex;
    justify-content: space-between;
   
  }
  .forgot-link{
  
  width: 128px;
  height: 21px;
  text-align: center;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #001323;
  opacity: 1;
  margin-right: 11px;
  margin-bottom:40px
  }
  .forgot-link , .forgot-link:hover {
    color: #001323;
  }
  
  .content{
  margin-top: 20px;
  font-size: 13.5px;
  text-align: center;
  letter-spacing: 0px;
  color: #001323;
  opacity: 1;
  }
  