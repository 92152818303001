.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.title-signup {
  margin-top: 15px;
}

.signupimage {
  margin-top: 40px;
}

.modal-card {
  width: 100%;
  max-width: 475px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px #00000080;
  border-radius: 6px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  padding: 40px 16px 16px 16px;
      max-height: 90vh;
        overflow-y: auto;
}

.overlay-wraper {
  z-index: 100;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #00000054;
  top: 0;
  left: 0;
}

.login-logo {
  margin: 0 auto;
  display: flex;
}

.login-modal-title {
  text-align: center;
  margin: 20px 0;
  font-weight: 600;
}

.form-control {
  width: 100%;
}

.form-footer {
  width: 100%;
}

.textfield {
  width: 396px;
  height: 56px;
  background: #F4F7FE 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
}

input[type="checkbox"].keepMeLogin {

  width: 16px;
  height: 16px;
  margin: 0;
}

.keep-login-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

.keep-login-label {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.form-footer {
  display: flex;
  justify-content: space-between;

}

.forgot-password {
  cursor: pointer;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  color: #000;
}

.reserve-rights-content {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #001323;
  margin-bottom: 0;
}

.button-wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.button-wrapper .button-common {
  padding: 10px 20px;
  border: 1px solid #0abe29;
  background: #fff;
  border-radius: 4px;
  font-size: 16px;
  color: #001323;
  flex: 1;
  font-weight: 600;
}

.button-wrapper .button-common.filled {
  background-color: #0abe29;
  color: #fff;
}