.data-item-card {
    display: flex;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 0px 8px #00000024;
    margin-bottom: 20px;
    gap: 16px;
    overflow: hidden;
    position: relative;
}

.data-item-card .map-img {
    width: 140px;
    height: 160px;
}

.data-item-card .map-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.data-item-card .detail-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0;
    flex: 1;
    width: 0;
}

.data-item-card .detail-wrap p {
    margin: 0;
    color: #001323;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.data-item-card .detail-wrap p b {
    font-weight: 7 00;
}

.data-item-card .action-wrap {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 10px 10px 10px 0;
}

.data-item-card .action-wrap .btn-wrap {
    display: flex;
    gap: 10px;
}

.data-item-card .action-wrap .btn-wrap .theme-btn {
    background-color: #0abe29;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #0abe29;
    padding: 5px;
    min-width: 100px;
    flex: 1;
    font-weight: 600;
}

.data-item-card .action-wrap .btn-wrap .theme-btn.bordered {
    background-color: transparent;
    color: #001323;
}

.data-item-card .action-wrap .status {
    text-align: end;
    margin: 20px 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
}

.data-item-card .action-wrap p.status .available {
    color: #0abe29;
}

.data-item-card .action-wrap p.status .notAvailable {
    color: #da020b;
}

.list-favorite-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 100%;
}
.status-button {
    gap: 10px;
}
.status-button .single-btn {
    height: 30px;
    width: 30px;
    border-radius: 4px;
    background-color: #A7A7A7;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
}
input:checked.property-status+label > div {
    background: #0abe29;
}
.verify-label img.verify-icon {
    height: 40px;
}
.verify-label img.verify-icon.unverified {
    filter: invert(82%) sepia(0%) saturate(16%) hue-rotate(148deg) brightness(88%) contrast(72%);
}
.verify-label .verify-stetus {
    font-size: 14px;
    font-weight: 600;
}
.unverified-card {
    opacity: 0.5;
}
@media (max-width: 767.98px) {
    .data-item-card .action-wrap .btn-wrap {
        flex-direction: column;
    }

    .data-item-card .action-wrap .status {
        margin: 14px 0;
    }

    .data-item-card .action-wrap .status {
        position: absolute;
        background: #fff;
        border: 1px solid #000;
        height: 40px;
        width: 40px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 10px;
        left: 10px;
        font-size: 30px;
        margin: 0;
    }
}

@media (max-width: 575.98px) {
    .data-item-card {
        flex-direction: column;
    }

    .data-item-card .map-img {
        width: 100%;
        height: 190px;
    }

    .data-item-card .detail-wrap {
        width: 100%;
        padding: 0 12px;
    }

    .data-item-card .action-wrap {
        padding: 0px 12px;
    }

    .data-item-card .action-wrap .btn-wrap {
        flex-direction: row;
    }

    .data-item-card .action-wrap .status {
        margin-top: 0;
    }
}