.loader-conteiner {
    position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        background-color: #000000bf;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
}
 .loader-95 {
     width: 80px;
     height: 80px;
     display: inline-block;
     position: relative;
 }

.loader-95::after {
         content: '';
         width: 80px;
         height: 80px;
         left: 0;
         bottom: 0;
         position: absolute;
         border-radius: 50% 50% 0;
         border: 30px solid #0bbf29;
         transform: rotate(45deg) translate(0, 0);
         animation: animMarker 0.4s ease-in-out infinite alternate;
     }

 .loader-95::before {
         content: '';
         position: absolute;
         left: 0;
         right: 0;
         margin: auto;
         top: 150%;
         width: 80px/ 2;
         height: 4px;
         border-radius: 50%;
         background: rgba(255, 255, 255, 0.2);
         animation: animShadow 0.4s ease-in-out infinite alternate;

 }

   @keyframes animMarker {
       0% {
           transform: rotate(45deg) translate(5px, 5px)
       }

       100% {
           transform: rotate(45deg) translate(-5px, -5px)
       }
   }

   @keyframes animShadow {
       0% {
           transform: scale(0.5)
       }

       100% {
           transform: scale(1)
       }
   }