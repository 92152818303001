.tabs-button-list {
    width: 100%;
}

.nav-pills .nav-link {
    color: #001323;
    font-size: 18px;
    font-weight: 600;
    border-radius: 0;
    padding: 20px;
    border-right: 2px solid transparent;
    border-top: 1px solid #E7E7F4;
    border-right: 1px solid #E7E7F4;
    position: relative;
}

.nav-pills .nav-link:last-child {
    border-bottom: 1px solid #E7E7F4;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #F5F5F5;
    color: unset;
    border-right: 2px solid #F2941B;
}

.nav-pills .nav-link.active::before,
.nav-pills .show>.nav-link::before {
    content: '';
    height: 16px;
    width: 16px;
    border-right: 8px solid #F2941B;
    border-bottom: 8px solid #F2941B;
    border-left: 8px solid transparent;
    border-top: 8px solid transparent;
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
}
.tab-list {
    margin-left: -20px;
}

@media (max-width: 991.98px) {
    .tabs-button-list {
        display: flex;
        flex-direction: row !important;
        width: 100%;
        flex-wrap: nowrap;
        overflow-y: auto;
        margin-bottom: 20px;
        padding: 20px;
    }

    .tabs-button-list .nav-link {
        white-space: nowrap;
    }

    .nav-pills .nav-link {
        border-bottom: 1px solid #E7E7F4;
    }

    .nav-pills .nav-link:first-child {
        border-left: 1px solid #E7E7F4;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        border-right: 1px solid #E7E7F4;
        border-bottom: 2px solid #F2941B;
    }

    .nav-pills .nav-link.active::before,
    .nav-pills .show>.nav-link::before {
        position: absolute;
        right: 50%;
        top: 100%;
        transform: translateY(-50%) rotate(45deg);
    }
    .tab-data-wrap > div {
        padding: 0;
    }
}
