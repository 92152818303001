.profile-modal-card {
    padding: 100px 20px 20px 20px;
}

.profile-img-container {
    height: 110px;
    width: calc(100% + 40px);
    background: #e4e6e7;
    margin-left: -20px;
    margin-top: -20px;
    position: relative;
    margin-bottom: 90px;
}

.profile-img-container .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.name-title {
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
}

.profile-detail {
    margin: 40px auto 20px auto;
    max-width: 400px;
}

.profile-detail .single-item {
    margin-bottom: 24px;
}

.profile-detail .single-item:last-child {
    margin-bottom: 0px;
}

.profile-detail .single-item label {
    font-weight: 600;
    color: rgb(167, 167, 167);
    margin-bottom: 2px;
    background: #f2f2f2;
    width: 100%;
    text-align: left;
    padding: 0px 10px;
}

.profile-detail .single-item label i {
    min-width: 20px;
    text-align: center;
}

.profile-detail .single-item p.info {
    font-size: 22px;
    margin: 0;
    text-align: left;
    padding: 0px 10px;
}

.profile-detail .single-item .form-control {
    padding: 10px;
    text-align: left;
    border-top: 0px;
    margin-top: -2px;
}

.profile-detail .single-item .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
    background-color: transparent;
}

.profile-btn-group {
    display: flex;
    max-width: 400px;
    justify-content: center;
}

.profile-btn-group button {
    min-width: 50% !important;
}