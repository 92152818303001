.forgot-modal {
    position: fixed;
    z-index: 101;
    top: 50%;
    left: 50%;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 0px 4px #0000004d;
    min-width: 500px;
    max-height: calc(100vh - 10vh);
    overflow-y: auto;
    max-width: 500px;
}

.forgot-modal .modal-header {
    border-bottom: 1px solid #e7e7f4;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.forgot-modal .modal-body {
padding: 10px;
}

.forgot-modal .modal-footer {
        border-top: 1px solid #e7e7f4;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
}

.forgot-modal .modal-header .close-btn {
    font-size: 24px;
    cursor: pointer;
}

.overlay-wraper {
    z-index: 100;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #00000054;
    top: 0;
    left: 0;
}

@media (max-width: 575.98px) {
    .forgot-modal {
        min-width: 96%;
        max-width: 96%;
    }
}