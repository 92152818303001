@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

.single-card {
    background-color: #f8f8f8;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px #0000004d;
    margin-top: 24px;
    gap: 10px;
}

.single-card .plan-detail {
    flex: 1;
}

.single-card .plan-detail .plan-title {
    gap: 10px;
}

.single-card .plan-detail .plan-title div {
    height: 24px;
    width: 24px;
    background-color: #ececec;
    border-radius: 100%;
}

.single-card .plan-detail .plan-title h4 {
    margin: 0;
    line-height: 1;
}

.single-card .plan-benifits {
    margin-top: 20px;
}

.single-card .plan-benifits ul {
    margin-bottom: 10px;
    list-style-type: none;
}

.single-card .plan-benifits ul li {
    position: relative;
}

.single-card .plan-benifits ul li:before {
    content: '\f00c';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: -24px;
}

.single-card .Plan-price {
    text-align: right;
}

.single-card .Plan-price h4 {
    font-weight: 700;
}

.price-card {
    cursor: pointer;
    content: "";
    width: 100%;
    height: 250px;
    display: inline-block;
    position: relative;
    margin: 5px;
    top: 6px;
    border-radius: 12px;
    z-index: 0;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .1);
    padding: 20px;
}

.Silver {
    background: linear-gradient(45deg, rgb(198, 197, 197) 0%, rgba(252, 252, 252, 1) 56%, rgba(232, 232, 232, 1) 96%);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(160, 160, 160, 0.3);
    border-right: 1px solid rgba(160, 160, 160, 0.5);
    box-shadow: inset 0px 0px 2px 2px rgba(150, 150, 150, .05);
    border: 2px solid #c5c4c4;
}

.Gold {
    background: linear-gradient(45deg, rgb(231, 213, 76) 0%, rgb(255, 253, 163) 56%, rgb(221, 211, 66) 96%);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(242, 215, 12, 0.3);
    border-right: 1px solid rgba(242, 215, 12, 0.3);
    box-shadow: inset 0px 0px 2px 2px rgba(150, 150, 150, .05);
    border: 2px solid #e9d750;
}

.Platinum {
    background: linear-gradient(45deg, rgb(170, 170, 170) 0%, rgb(225, 225, 225) 56%, rgb(218, 218, 218) 96%);
    border-top: 1px solid rgba(255, 254, 254, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(160, 160, 160, 0.3);
    border-right: 1px solid rgba(160, 160, 160, 0.5);
    box-shadow: inset 0px 0px 2px 2px rgba(150, 150, 150, .05);
    border: 2px solid #adadad;
}

.price-card h4.card-title {
    font-size: 55px;
    font-weight: 700;
    font-family: 'Dancing Script', cursive;
    opacity: 0.3;
    position: relative;
    top: -10px;
}

.price-card h4.card-title.Silver-text {
    color: #858585;
}

.price-card h4.card-title.Gold-text {
    color: #9d923b;
}

.price-card h4.card-title.Platinum-text {
    color: #6b6868;
}

.price-card .price-tag {
    position: absolute;
    bottom: 30px;
    left: 20px;
    font-size: 28px;
    font-weight: 700;
}

.price-card .subscribe-btn {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 2px 10px;
    background-color: transparent;
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-weight: 600;
}

.price-card:hover {
    border: 2px solid rgb(125, 125, 125);
}

.subscriprion_input+label.price-card {
    position: relative;
    top: 0px;
    transition: 0.5s;
}

.subscriprion_input:checked+label.price-card {
    top: -24px;
}

.subscriprion_input+label.price-card::after {
    content: '';
    height: 5px;
    width: 5%;
    background: radial-gradient(rgba(0, 0, 0, 0.425), rgba(0, 0, 0, 0.329), rgba(0, 0, 0, 0.128), transparent, transparent, transparent);
    position: absolute;
    bottom: 0px;
    left: 50%;
    border-radius: 100%;
    transition: 0.5s;
    transform: translateX(-50%);
    opacity: 0;
}

.subscriprion_input:checked+label.price-card::after {
    height: 20px;
    width: 100%;
    bottom: -32px;
    opacity: 1;
}

.add-card-btn {
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    flex: 0 !important;
}

.payment-card {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
}

.payment-card .sub-card {
    padding: 10px;
    border-bottom: 1px solid #e1e1e1;
}

.payment-card .sub-card:last-child {
    border-bottom: 0px;
}

.payment-card .sub-card p.discription {
    font-size: 14px;
}

.payment-wrraper .section-title {
    background-color: #f2f2f2;
    padding: 5px 10px;
}

.payment-wrraper .total-amount {
    min-height: 80px;
}

.add-card-form input {
    padding: 10px;
}
.saved-card-options {
    cursor: pointer;
    flex: 1;
}
.single-card-item:hover {
    background-color: #f2f2f2;
}
.saved-card-options input {
    height: 40px;
    width: 20px;
}
.del-icon {
    cursor: pointer;
    margin: 0px 10px;
}